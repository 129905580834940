import { goodsSkuPage } from "@/api/goods/goods"
import { mapGetters } from "vuex"
import { goodsCategoryInfo, tree,getGoodsAttrs } from "@/api/goods/goodscategory"
import { addCollect, deleteCollect, isCollect } from "@/api/goods/goods_collect"
import { navList } from '@/api/website';
export default {
    data: () => {
        return {
            cargoList: [],
            shopList: [], // 店铺列表
            brandList: [], // 品牌列表
            attributeList: [], // 属性列表
            brandInitialList: [],
            currentInitial: "", // 当前选择品牌分区
            choosedBrand: "", // 已选择的品牌,
            hasChoosedAttrs: false, // 忆选择的属性
            factory_collapse: true,//生产厂家区域折叠
            dosage_form_index: "",//查询剂型index
            streamer: [],
            first_child_list: [],
            showgoodsList: 1,//是否显示商品列表
            first_index: 0,//一级分类id
            catewords: '',
            factory: [],//生产厂家
            spec: [],//查询规格
            dosage_form: [],//查询剂型
            factory_index: "",//生产厂家index
            spec_index: "",//查询规格index
            dosage_form_collapse: true,
            spec_collapse: true,
            total: 0,
            keyword: "",
            currentPage: 1,
            pageSize: 12,
            is_free_shipping: 0,
            is_own: "",
            filters: {
                site_id: 0,
                category_id: 0,
                category_level: 0,
                brand_id: 0,
                min_price: "",
                max_price: "",
                order: "",
                sort: "desc",
                coupon: 0,
            },
            loading: true,
            whetherCollection: 0,
            first_index: 0,
            two_index: 0,
            first_index_active: 0,
            testingNumberState: false,
            number:"",
            promotion_type: '',
            dosageFormStr:'',
            specStr: "",
            factoryStr:'',
            subCategory: [],//切换样式模块数据
            viewStyle: 1,//样式模块切换
            subCategory: [],//切换样式模块数据
            loadingList:false
        }
    },
    created() {
        this.getnavList()
        this.specData()
        this.specData()
        this.dosageForm()
        this.goodscategory()
        this.keyword = this.$route.query.keyword || ""
        if (this.$route.query.keyword) window.document.title = `${this.$route.query.keyword} - ${this.siteInfo.site_name}`

        this.filters.category_id = this.$route.query.category_id || ""
        this.filters.category_level = this.$route.query.level || ""
        this.filters.brand_id = this.$route.query.brand_id || ""
        this.filters.coupon = this.$route.query.coupon || 0
        this.filters={
            ...this.filters,
            ...this.$route.query
        }
        this.getGoodsList()
        if (this.$route.query.category_id) {
            this.categorySearch()
        } else {
            this.first_child_list = [];
        }
    },
    computed: {
        ...mapGetters(["defaultGoodsImage", "siteInfo"])
    },
    methods: {
        setMainWidth() {
            if(this.viewStyle == 2){
                document.getElementsByClassName("el-main")[0].style['width'] = '100%'
                document.getElementsByClassName("list-wrap")[0].style['width'] = '1200px';
                document.getElementsByClassName("list-wrap")[0].style['margin'] =  "0 auto";
            }
        },
        uploadId(val) {
            this.filters.category_id = val
            this.getGoodsList()
        },
        imageError(item) {
            item.sku_image = this.defaultGoodsImage
        },
        upnumber(val) {
            this.number = val
        },
        getnavList() {
            navList({})
                .then(res => {
                    let { code, data } = res
                    if (code >= 0) {
                        // console.log(data)
                        //判断是否为当前页面数据
                        const path = this.$route.fullPath
                        // let wbstyleStart = path.indexOf("&wbstyle=")
                        // let wbstyleStart2 = path.indexOf("?wbstyle=")
                        // let isurl = wbstyleStart != -1 ? wbstyleStart : wbstyleStart2
                        // let extractedString = path.substring(0, isurl)
                        data.forEach(item => {
                            //过滤当前为空数据
                            let urlId = JSON.parse(item.nav_url).url
                            if (this.$route.fullPath == urlId) {
                                this.viewStyle = item.view_style
                                let category = JSON.parse(item.sub_category)
                                this.banners = JSON.parse(item.banners)
                                this.streamer = JSON.parse(item.streamer)
                                if (category.length > 0) {
                                    this.subCategory = category
                                }
                                this.setMainWidth()
                            }
                        })
                    }
                }).catch((err) => {

                })
        },
           // 品牌赛选
              setBrand(brandItem) {
                if (brandItem == "") {
                    this.brand_index = brandItem
                    this.brand_collapse = true
                } else {
                    this.brand_index = brandItem
                }
                // this.filters.category_id = 0
                // this.factoryStr = ""
                // this.specStr = ""
                // this.dosageFormStr=""
                this.brandStr = brandItem
                this.barndInput = ''
                this.getGoodsList()
            },
        //剂型筛选
        setDosageForm(dosageItem) {
            if (dosageItem == "") {
                this.dosage_form_index = dosageItem
                this.dosage_form_collapse = true
            } else {
                this.dosage_form_index = dosageItem
            }
            // this.filters.category_id = 0
            // this.factoryStr = ""
            // this.specStr = ""
            // this.brandStr=""
            this.dosageFormStr = dosageItem
            this.inputtype = ''
            this.getGoodsList()
        },
        // 规格筛选
        setSpec(specItem) {
            if (specItem == '') {
                this.spec_index = specItem
                this.spec_collapse = true
            } else {
                this.spec_index = specItem;
            }
            // this.filters.category_id = 0
            // this.dosageFormStr = ""
            // this.factoryStr = ""
            // this.brandStr=""
            this.specificationInput = ''
            this.specStr = specItem;
            this.getGoodsList()
        },
        collapse(val, index) {
            switch (index) {
                case 1:
                    this.factory_collapse = val
                    break;
                case 2:
                    this.spec_collapse = val
                    break;
                case 3:
                    this.brand_collapse = val
                    break;
                case 4:
                    this.dosage_form_collapse = val
                    break;
            }

        },
        // 剂型
        dosageForm() {
            let params = {
                goods_attr: "attr_dosage_form",
                keyword: this.keyword,
                category_id: this.filters.category_id,
                brand_id: this.filters.brand_id,
                tag_id: this.filters.tag_id
            }
            getGoodsAttrs(params).then(res => {
                if (res.code === 0 && res.data) {
                    this.dosage_form = res.data
                }
            }).catch(err => {
            })
        },
        //规格
        specData() {
            let params = {
                goods_attr: "attr_specs",
                keyword: this.keyword,
                category_id: this.filters.category_id,
                brand_id: this.filters.brand_id,
                tag_id: this.filters.tag_id
            }
            getGoodsAttrs(params).then(res => {
                if (res.code === 0 && res.data) {
                    this.spec = res.data
                }
            }).catch(err => {
            })
        },
        /*
     筛选厂家数据
      */
        goodscategory() {
            let params = {
                goods_attr: "attr_factory",
                keyword: this.keyword,
                category_id: this.filters.category_id,
                brand_id: this.filters.brand_id,
                tag_id: this.filters.tag_id
            }
            getGoodsAttrs(params).then(res => {
                if (res.code === 0 && res.data) {
                    this.factory = res.data
                }
            }).catch(err => {
            })
        },
        getTree(categoryConfig) {
            tree({
                level: 3,
                template: 2
            })
                .then(res => {
                    if (res.code == 0 && res.data) {
                            this.first_child_list = res.data
                        // for(let i =0;i<this.goodsCategoryTree.length;i++) {
                        //     if(this.goodsCategoryTree[i].child_list > 3) {
                        //         this.isHide = true
                        //     }
                        // }
                    }
                })
                .catch(err => {
                    this.$message.error(err.message)
                })
        },
        // 生产厂家筛选
        setFactory(factoryItem) {
            // 这里判断是因为点击全选时会影响接口数据  传入空字符表示为全部 (厂家,规格，剂型：都是这样判断)
            if (factoryItem == "") {
                this.factory_index = factoryItem
                this.factory_collapse = true
            } else {
                this.factory_index = factoryItem;
            }
            // this.filters.category_id = 0
            // this.specStr = ""
            // this.dosageFormStr = ""
            // this.brandStr=""
            this.inptName = ''
            this.factoryStr = factoryItem;
            this.getGoodsList()
        },
        /**
         * 商品分类搜索
         */
        categorySearch() {
            goodsCategoryInfo({
                category_id: this.filters.category_id
            }).then(res => {
                if (res.code == 0 && res.data) {
                    this.catewords = res.data.category_full_name
                    this.first_index = res.data.category_id_1
                    this.two_index = res.data.category_id_2
                    this.first_child_list = res.data.child_list
                    this.first_index_active = 0
                    window.document.title = `${res.data.category_name} - ${this.siteInfo.site_name}`
                }

            }).catch(err => {

            })
        },
        // 厂家，剂药，规格搜索
        changeinout(val, num) {

            // 判断传入不同下标
            switch (num) {
                case 1:
                    // this. filters.category_id = val.category_id
                    this.factory_index = val.factory_index
                    this.factoryStr = val.factoryStr
                    // this.specStr =val.specStr
                    // this.dosageFormStr = val.dosageFormStr
                    this.factory_collapse = val.factory_collapsetype
                    this.getGoodsList()
                    this.inptName = ''
                    break
                case 2:
                    // this.filters.category_id = val.category_id
                    // this.dosageFormStr = val.dosageFormStr
                    // this.factoryStr = val.factoryStr
                    this.spec_index = val.spec_index
                    this.specStr = val.specStr
                    this.getGoodsList()
                    this.spec_collapse = val.spec_collapsetype
                    break
                case 3:
                    // this.filters.category_id =val.category_id
                    // this.factoryStr = val.factoryStr
                    // this.specStr = val.specStr
                    this.dosage_form_index = val.dosage_form_index
                    this.dosageFormStr = val.dosageFormStr
                    this.getGoodsList()
                    this.dosage_form_collapse = val.dosage_form_collapse

                    break
                case 4:
                    // this.filters.category_id = val.category_id
                    // this.factoryStr = val.factoryStr
                    // this.specStr = val.specStr
                    // this.dosageFormStr=val.dosageFormStr
                    this.brand_index = val.brand_index
                    this.brandStr = val.brandStr
                    this.getGoodsList()
                    this.brand_collapse = val.brand_collapsetype
                    break
            }

        },
        setFiindex(item) {
            this.first_index_active = item.category_id
            this.currentPage = 1
            window.document.title = `${item.category_name} - ${this.siteInfo.site_name}`
            this.getGoodsList()
        },
        setSiindex() {
            // this.factoryStr = ""
            // this.specStr = ""
            // this.dosageFormStr = ""
            // this.first_index_active = 0
            // this.filters.category_id = 0
            this.currentPage = 1
            if (this.first_child_list.length === 0) {
                this.getTree()
            }
            this.getGoodsList()
        },
        addToCart(item) {
            this.$store
                .dispatch('cart/add_to_cart', item)
                .then(res => {
                    var data = res.data
                    if (data > 0) {
                        this.$message({
                            message: "加入购物车成功",
                            type: "success"
                        })
                    }
                })
                .catch(err => err);
        },
        async isCollect(item) {
            await isCollect({ goods_id: item.goods_id }).then(res => {
                this.whetherCollection = res.data
                if (this.whetherCollection == 0) {
                    item.isCollection = false
                } else {
                    item.isCollection = true
                }
            })
                .catch(err => err);
        },
        async editCollection(item) {
            await this.isCollect(item)
            const { goods_id, sku_id, site_id, sku_name, sku_price, sku_image } = item;
            if (this.whetherCollection == 0) {
                await addCollect({ goods_id, sku_id, site_id, sku_name, sku_price, sku_image })
                    .then(res => {
                        this.$message({
                            message: '收藏成功',
                            type: 'success'
                        });
                        item.isCollection = true
                    })
                    .catch(err => err);
            } else {
                await deleteCollect({
                    goods_id
                }).then(res => {
                    if (res.data > 0) {
                        this.$message({
                            message: '取消收藏成功',
                            type: 'success'
                        });
                        item.isCollection = false
                    }
                })
                    .catch(err => err);
            }
        },
        getGoodsList() {
            const currentArr = []
            if (this.attributeList) {
                this.attributeList.forEach(item => {
                    if (item.child) {
                        item.child.forEach(subitem => {
                            if (subitem.selected) {
                                currentArr.push({
                                    attr_id: item.attr_id,
                                    attr_value_id: subitem.attr_value_id
                                })
                            }
                        })
                    }
                })
            }

            const params = {
                page: this.currentPage,
                page_size: this.pageSize,
                site_id: this.filters.siteId,
                keyword: this.keyword,
                keyword: this.keyword,
                factory: this.factoryStr,
                spec: this.specStr,
                dosage_form: this.dosageFormStr,
                attr: currentArr.length > 0 ? JSON.stringify(currentArr) : "",
                ...this.filters
            }

            goodsSkuPage(params || {})
                .then(res => {
                    const { count, page_count, list } = res.data
                    this.total = count
                    this.cargoList = list
                    this.cargoList.forEach((item,index)=>{
                        this.$set(item,"show_ptitl", false)
                     })
                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                })
        },


        onChooseBrand(brand) {
            this.choosedBrand = brand
            this.filters.brand_id = brand.id
            this.getGoodsList()
        },

        setMuiltChoose(attr) {
            this.$set(attr, "isMuiltSelect", !attr.isMuiltSelect)
            this.getGoodsList()
        },

        setAttrSelected(item, subitem) {
            if (item.child) {
                item.child.forEach(attr => {
                    this.$set(attr, "selected", false)
                })
            }

            this.$set(subitem, "selected", true)
            this.$set(item, "selectedValue", subitem.attr_value_name)
            this.getGoodsList()
        },

        setAttrSelectedMuilt(item, subitem) {
            this.$set(subitem, "selected", !subitem.selected)
            var selectedValueArray = []

            if (subitem.selected) {
                const selectedValue = item.selectedValue || ""
                selectedValueArray = selectedValue.split(",")
                if (selectedValueArray[0] == "") selectedValueArray.pop(0)

                if (selectedValueArray.indexOf(subitem.attr_value_name) == -1) {
                    selectedValueArray.push(subitem.attr_value_name)
                }
            } else {
                const selectedValue = item.selectedValue || ""
                selectedValueArray = selectedValue.split(",")
                if (selectedValueArray[0] == "") selectedValueArray.pop(0)

                if (selectedValueArray.indexOf(subitem.attr_value_name) !== -1) {
                    selectedValueArray.splice(selectedValueArray.indexOf(subitem.attr_value_name), 1)
                }
            }

            this.$set(item, "selectedValue", selectedValueArray.toString())
            this.getGoodsList()
        },

        colseBrand() {
            this.choosedBrand = ""
            this.filters.brand_id = ""
            this.getGoodsList()
        },

        colseAttr(item) {
            item.selectedValue = ""
            item.child.forEach(subitem => {
                subitem.selected = false
            })

            item.isMuiltSelect = false

            this.getGoodsList()
        },

        handlePageSizeChange(size) {
            this.pageSize = size
            this.getGoodsList()
        },
        handleCurrentPageChange(page) {
            this.currentPage = page
            this.getGoodsList()
        },
        handlePriceRange() {
            if (Number(this.filters.min_price) > Number(this.filters.max_price)) {
                // es6解构赋值
                [this.filters.min_price, this.filters.max_price] = [this.filters.max_price, this.filters.min_price]
            }
            this.getGoodsList()
        },
        handleChangeInitial(initial) {
            this.currentInitial = initial
        },
        changeSort(type) {
            if (this.filters.order === type) {
                this.$set(this.filters, "sort", this.filters.sort === "desc" ? "asc" : "desc")
            } else {
                this.$set(this.filters, "order", type)
                this.$set(this.filters, "sort", "desc")
            }

            this.getGoodsList()
        }
    },
    watch: {
        keyword(newVal) {
            this.goodscategory()
            this.specData()
            this.dosageForm()
            this.getGoodsList()
        },
        is_free_shipping: function (val) {
            this.filters.is_free_shipping = val ? 1 : ""
            this.getGoodsList()
        },
        is_own: function (val) {
            this.filters.is_own = val ? 1 : ""
            this.getGoodsList()
        },
        $route: function (curr) {
            if (curr.query.keyword) window.document.title = `${curr.query.keyword} - ${this.siteInfo.site_name}`
            if (curr.query.level) {
                this.filters.category_level = curr.query.level
                this.filters.category_id = curr.query.category_id
                this.getGoodsList()
                if (curr.query.level != 3) {
                    this.categorySearch()
                }
            }
            if (curr.query.category_id == undefined) {
                this.catewords = ""
                this.currentPage = 1
                this.keyword = curr.query.keyword
                this.first_child_list = [];
                this.filters.category_id = curr.query.category_id || ""
                this.filters.category_level = curr.query.level || ""
                this.filters.brand_id = curr.query.brand_id || ""
                this.getGoodsList()
            }
            this.goodscategory()
            this.specData()
            this.dosageForm()
        }
    }
}
