<template>
    <div class="goods-list" v-loading="loading">
        <template v-if="viewStyle != 2">
                    <!-- 搜索关键字 -->
        <div class="search_bread" v-if="keyword">
            <router-link to="/">首页</router-link>
            <span class="padd-10">/</span>
            <span class="keyword">{{ keyword}}</span>
        </div>
        <div class="search_bread" v-else-if="!keyword && catewords">
            <router-link to="/">首页</router-link>
            <span class="padd-10">/</span>
            <router-link
                :to="{ path: '/list', query: { category_id: first_index, level: 1 } }"
            >{{ catewords.split('/')[0] }}</router-link>
            <!-- <span class="keyword">{{ catewords.split('/')[0] }}</span> -->
            <span
                v-if="(filters.category_level == 2 || filters.category_level == 3) && catewords.split('/')[1]"
                class="padd-10"
            >/</span>
            <span
                v-if="(filters.category_level == 2 || filters.category_level == 3) && catewords.split('/')[1]"
                class="keyword"
            >{{ catewords.split('/')[1] }}</span>
        </div>
        <div v-if="!keyword && first_child_list" class="search_bread">
			<p>分类：</p>
			<ul>
				<li :class="{active: first_index_active == 0}" @click="setSiindex()"><router-link :to="{ path: '/list', query: { category_id: two_index, level: 2 } }">全部</router-link></li>
				<li :class="{active: item.category_id == first_index_active}" @click="setFiindex(item)" v-for="item in first_child_list"><router-link :to="{ path: '/list', query: { category_id: item.category_id, level: item.level } }">{{item.category_name}}</router-link></li>
			</ul>
        </div>
        </template>
        <!-- 楼层一 -->
        <screen-list1
          v-if="viewStyle == 1"
            @setFiindex="setFiindex"
            @setFactory="setFactory"
            @changeinout="changeinout"
            @setDosageForm="setDosageForm"
            @collapse="collapse"
            @setSpec="setSpec"
            @setBrand="setBrand"
            :first_child_listtype="first_child_list"
            :keyword="keyword"
            :filters="filters"
            :factory="factory"
            :spec="spec"
            :dosage_form="dosage_form"
            :first_index_active="first_index_active"
            :first_index="first_index"
            :factory_index="factory_index"
            :spec_collapse="spec_collapse"
            :spec_index="spec_index"
            :factory_collapse="factory_collapse"
            :dosage_form_collapse="dosage_form_collapse"
            :dosage_form_index="dosage_form_index"
            :streamer="streamer"
            :showgoodsList="showgoodsList"
        ></screen-list1>
        <screen-list2
            v-if="viewStyle == 2"
            :data="subCategory"
            :loadingAd="loading"
            @uploadId="uploadId"
            :banners="banners"
            :streamer="streamer"
        ></screen-list2>
        <screen-list3
            v-if="viewStyle == 3"
            @setFiindex="setFiindex"
            @setFactory="setFactory"
            @changeinout="changeinout"
            @collapse="collapse"
            @setSpec="setSpec"
            @setBrand="setBrand"
           @setDosageForm="setDosageForm"
            @changeSort="changeSort"
            @handlePriceRange="handlePriceRange"
            :first_child_listtype="first_child_list"
            :keyword="keyword"
            :filters="filters"
            :factory="factory"
            :spec="spec"
            :dosage_form="dosage_form"
            :first_index_active="first_index_active"
            :first_index="first_index"
            :factory_index="factory_index"
            :spec_collapse="spec_collapse"
            :spec_index="spec_index"
            :factory_collapse="factory_collapse"
            :dosage_form_collapse="dosage_form_collapse"
            :dosage_form_index="dosage_form_index"
            :showgoodsList="showgoodsList"
            :streamer="streamer"
        ></screen-list3>

        <!-- 品牌属性筛选区 -->
        <template>
            <div class="category" v-if="brandList.length || attributeList.length">
                <!-- 属性 -->
                <div class="brand" v-for="item in attributeList" :key="'attr' + item.attr_id">
                    <div class="table_head">{{ item.attr_name }}：</div>
                    <div class="table_body">
                        <div class="content">
                            <span v-for="subitem in item.child" :key="subitem.attr_value_id">
                                <el-checkbox
                                    :label="subitem.attr_value_name"
                                    v-if="item.isMuiltSelect"
                                    :checked="subitem.selected"
                                    @change="setAttrSelectedMuilt(item, subitem)"
                                ></el-checkbox>
                                <el-link
                                    :underline="false"
                                    v-else
                                    @click="setAttrSelected(item, subitem)"
                                >{{ subitem.attr_value_name }}</el-link>
                            </span>
                        </div>
                    </div>
                    <div class="table_op">
                        <el-button
                            size="small"
                            icon="el-icon-circle-plus-outline"
                            @click="setMuiltChoose(item)"
                        >多选</el-button>
                    </div>
                </div>
            </div>
        </template>

        <div class="list-wrap">
            <div class="goods-recommended" v-if="cargoList.length">
                <goods-recommend :page-size="cargoList.length < 5 ? 2 : 5" />
            </div>
            <div class="list-right">
                <!-- 排序筛选区 -->
                <div>
                    <div class="sort">
                        <div class="item" @click="changeSort('')">
                            <div class="item-name">综合</div>
                        </div>
                        <div class="item" @click="changeSort('sale_num')">
                            <div class="item-name">销量</div>
                            <i
                                v-if="filters.order === 'sale_num' && filters.sort === 'desc'"
                                class="el-icon-arrow-down el-icon--down"
                            />
                            <i v-else class="el-icon-arrow-up el-icon--up" />
                        </div>
                        <div class="item" @click="changeSort('discount_price')">
                            <div class="item-name">价格</div>
                            <i
                                v-if="filters.order === 'discount_price' && filters.sort === 'desc'"
                                class="el-icon-arrow-down el-icon--down"
                            />
                            <i v-else class="el-icon-arrow-up el-icon--up" />
                        </div>
                        <div class="item-other">
                            <el-checkbox label="包邮" v-model="is_free_shipping"></el-checkbox>
                        </div>
                        <!-- <div class="item-other">
							<el-checkbox label="自营" v-model="is_own"></el-checkbox>
                        </div>-->
                        <div class="input-wrap">
                            <div class="price_range">
                                <el-input
                                    placeholder="最低价格"
                                    size="small"
                                    v-model="filters.min_price"
                                ></el-input>
                                <span>—</span>
                                <el-input
                                    placeholder="最高价格"
                                    size="small"
                                    v-model="filters.max_price"
                                ></el-input>
                            </div>
                            <el-button plain size="mini" @click="handlePriceRange">确定</el-button>
                        </div>
                    </div>
                </div>
                <div class="cargo-list" v-if="cargoList.length">
                    <div class="goods-info">
                       <div class="item" v-for="(item, index) in cargoList">
                           <goods-list-item :showLimit="item.promotion_label === 'seckill'" :goods="item" :up-down-layout="true"></goods-list-item>
                       </div>
                    </div>
                    <div class="pager">
                        <el-pagination
                            background
                            :pager-count="5"
                            :total="total"
                            prev-text="上一页"
                            next-text="下一页"
                            :current-page.sync="currentPage"
                            :page-size.sync="pageSize"
                            @size-change="handlePageSizeChange"
                            @current-change="handleCurrentPageChange"
                            hide-on-single-page
                        ></el-pagination>
                    </div>
                </div>
                <div class="empty" v-else>
                    <div class="ns-text-align">没有找到您想要的商品。换个条件试试吧</div>
                    <div class="outOfStock_btn" v-if="keyword">
                        <el-button @click="jump_outOfStock" type="primary" size="small">缺货登记</el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onTip } from "@/utils/tips"
import shareCounter from "@/components/shareCounter.vue"
import BreadCrumbs from "@/components/BreadCrumbs"
import GoodsRecommend from "@/components/GoodsRecommend"
import screenList1 from "./componet/screenList-1.vue"
import screenList2 from "./componet/screenList-2.vue"
import screenList3 from "./componet/screenList-3.vue"
import { copyRight, shopServiceLists, friendlyLink, weQrcode, websiteInfo } from "@/api/website"
import list from "./list"
import { mapGetters } from "vuex"
import GoodsListItem from "@/components/goods-list-item/goods-list-item.vue"

export default {
    name: "list",
    components: {
        GoodsListItem,
        BreadCrumbs,
        GoodsRecommend,
        screenList1,
        shareCounter,
        screenList2,
        screenList3
    },
    data: () => {
        return {
            showPTitl: false
        }
    },
    mixins: [list],
    created() {},
    updated(){
            this.$nextTick(() => {
                window.scrollTo(0, 0);
            });
    },
    computed: {
        ...mapGetters(["cartCount", "siteInfo", "member","token"]),
        logined: function() {
            return this.member !== undefined && this.member !== "" && this.member != {}
        }
    },
    methods: {
        togot(item){
            if(this.token){
                if( onTip(item.sale_control.view_price, item.sale_control?.is_member_by,this,item.sale_control?.not_buy_msg)) return
                this.$router.pushToTab({ path: '/sku-' + item.sku_id })
            }else{
                this.$router.push("/login")
            }
        },
        jump_outOfStock() {
            console.log("缺货登记")
            this.$router.push("/member/out_of_stock")
        },
        chengTitl(item) {
     item.show_ptitl = true
        },
           tochangTitl(item) {
           item.show_ptitl = false
        },
        showPrice(data) {
                 let price = data.discount_price
            if(!data.sale_control?.is_member_by || !data.sale_control?.view_price){
                price=data.sale_control?.not_buy_msg
            }

            if (data.member_price && parseFloat(data.member_price) < parseFloat(price)) price = data.member_price

            return price
        },
        // 加入购物车
        joinCart(item) {
            if (!this.$store.getters.token) {
                this.$router.push("/login")
                return
            }
            if (onTip(item.sale_control.view_price, item.sale_control?.is_member_by,this)) return
            this.$store
                .dispatch("cart/add_to_cart", {
                    site_id: item.site_id,
                    sku_id: item.sku_id,
                    num: this.number
                })
                .then(res => {
                    if (res.code === 0) {
                        this.$message({
                            message: "加入购物车成功",
                            type: "success"
                        })
                    }
                })
                .catch(err => {
                    if (err.message === "token不存在") {
                        this.$router.pushToTab("/login")
                    } else {
                        this.$message.error(err.message)
                    }
                })
        }
    }
}
</script>

<style lang="scss" scoped>
.outOfStock_btn{
display: flex;
align-items: center;
justify-content: center;
}
::v-deep .el-input-number--mini {
    width: 100px !important;
}
.exchange-guo {
    cursor: pointer;
 height: 25px;
 line-height: 25px;
    & > span {
        margin: 0 10px;
        display: inline-block;
        border-radius: 5px;
        padding: 0 5px;
        color: #ff547b;
        border: 1px solid #ff547b;
    }
}
.padd-10 {
    padding: 0 10px;
}
.goods-list {
    background: #fff;
    padding-top: 10px;
}
.sale-hint {
    font-size: 12px;
    color: red;
}
.search_bread {
    display: inline-block;
    font-size: 14px;
    margin: 0px auto;
    width: 100%;
    padding: 10px;
    p {
        float: left;
    }
    li {
        float: left;
        padding: 0 10px;
    }
    .active a {
        color: #fff !important;
        border: 1px solid #ff547b !important;
        padding: 5px;
        border-radius: 5px;
        background: #ff547b !important;
    }
}

.selected_border {
    border: 1px solid $base-color;
}

.attr_filter {
    .el-tag {
        margin-right: 5px;
        margin-bottom: 10px;
        border-radius: 0;

        .title {
            color: $base-color;
            font-size: 15px;
        }
    }
}

.category {
    margin: 0 auto 10px auto;
    border: 1px solid #eeeeee;

    .brand {
        border-bottom: 1px solid #eeeeee;
        display: flex;
        flex-direction: row;

        &:last-child {
            border-bottom: none;
        }

        .table_head {
            width: 140px;
            min-width: 140px;
            border-right: 1px solid #eeeeee;
            padding-left: 10px;
            padding-top: 10px;
            background-color: #f2f6fc;
        }

        .table_body {
            flex-grow: 1;

            .initial {
                margin: 5px auto 10px 10px;

                span {
                    border: 0;
                    margin: 0;
                    padding: 5px 10px;
                    border: 0;
                    border-radius: 0;

                    &:hover {
                        background-color: $base-color;
                        color: #ffffff;
                    }
                }
            }

            .content {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                padding: 10px;
                width: 900px;

                .brand-item {
                    margin-right: 10px;
                    margin-top: 10px;
                }

                .el-card {
                    width: 125px;
                    height: 45px;

                    &:hover {
                        border: 1px solid $base-color;
                        cursor: pointer;
                    }
                }

                span {
                    margin: auto 25px;
                }
            }
        }

        .table_op {
            margin-top: 5px;
            margin-right: 5px;
        }

        .el-image {
            width: 100%;
            height: 100%;
        }
    }
}

.list-wrap {
    overflow: hidden;
}

.goods-recommended {
    width: 200px;
    background-color: #fff;
    float: left;
    margin-right: 10px;
}

.sort {
    display: flex;
    align-items: center;

    .item {
        display: flex;
        align-items: center;
        padding: 5px 15px;
        border: 1px solid #f1f1f1;
        border-left: none;
        cursor: pointer;

        &:hover {
            background: $base-color;
            color: #fff;
        }
    }

    .item-other {
        display: flex;
        align-items: center;
        padding: 5px 15px;
        border: 1px solid #f1f1f1;
        border-left: none;
        cursor: pointer;
    }

    .input-wrap {
        display: flex;
        align-items: center;

        .price_range {
            margin-left: 60px;
        }

        span {
            padding-left: 10px;
        }

        .el-input {
            width: 150px;
            margin-left: 10px;
        }

        .el-button {
            margin: 0 17px;
        }
    }

    > div:first-child {
        border-left: 1px solid #f1f1f1;
    }
}

.goods-info {
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;
    .item{
        display: flex;
        flex-direction: column;
    }
    .cart-layout {
        margin-top:auto;
        padding: 0 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .el-input-number--mini {
            width: 85px !important;
        }
        .cart-num {
            margin-right: 10px;
            position: relative;
            width: 30%;
            border-radius: 4px;
            display: flex;
            border: 1px solid #ccc;
            justify-content: center;
            align-items: center;
            span {
                z-index: 1;
                position: absolute;
                cursor: pointer;
                width: 20px;
                text-align: center;
            }
            span:first-child {
                left: 0;
            }
            span:last-child {
                right: 0;
            }
            .number {
                .el-input {
                    .el-input__inner {
                        text-align: center;
                    }
                }
            }
        }
        .cart-add {
            font-size: 12px;
            margin-left: 5px;
            cursor: pointer;
            background: $base-color;
            color: $base-color-whtie;
            border-radius: 15px;
            padding: 2px 10px;
        }
        .ban{
            font-size: 12px;
            margin-left: 5px;
            cursor: not-allowed;
            background:#dddddd6e;
            border-radius: 15px;
            padding: 2px 10px;
                }
    }
    .item {
        width: 220px;
        margin: 10px 20px 0 0;
        border: 1px solid #eeeeee;
        //padding-top: 10px;
        padding: 10px;
        box-sizing: border-box;
        position: relative;
        //padding-bottom: 5px;
        .isno {
            position: absolute;
            min-height: 20px;
            left: 10px;
            bottom: 164px;
            padding: 5px;
            background: #ff547b;
            color: #fff;
            border-radius: 5px;
            &::after {
                position: absolute;
                left: 10px;
                bottom: 32px;
                content: "";
                display: inline-block;
                width: 0;
                height: 0;
              border-bottom: 10px solid #ff547b;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
            }
        }
        .exchange {
            position: absolute;
            right: 0;
            top: 0;
            padding: 5px;
            background: #4caf50;
            color: #fff;
            border-radius: 5px;
        }
        &:nth-child(4n) {
            margin-right: initial !important;
        }

        &:hover {
            border: 1px solid $base-color;
        }

        .img-wrap {
            width: 198px;
            height: 198px;
            cursor: pointer;
            padding: 10px;
        }

        .goods-name {
            padding: 0 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            word-break: break-all;
            cursor: pointer;

            &:hover {
                color: $base-color;
            }
        }
        .goods_shux {
            color: #ccc;
            font-size: 12px;
            padding: 0 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            word-break: break-all;
            cursor: pointer;
            width: 210px;
        }
        .price-wrap {
            padding: 0 10px;
            display: flex;
            align-items: center;

            .price {
                display: flex;
                color: $base-color;
                font-size: $ns-font-size-lg;

                p {
                    font-size: $ns-font-size-base;
                    display: flex;
                    align-items: flex-end;
                }
            }

            .market-price {
                color: #838383;
                text-decoration: line-through;
                margin-left: 10px;
            }
        }

        .sale-num {
            padding: 0 10px;
            display: flex;
            color: #838383;
            font-size: $ns-font-size-sm;

            p {
                color: #4759a8;
            }
        }

        .shop_name {
            padding: 0 10px;
            display: flex;
            color: #838383;
        }

        .saling {
            padding: 5px 10px;
            height:25px !important;
            line-height: 25px !important;
            display: flex;
            font-size: $ns-font-size-sm;
            .free-shipping {
                background: $base-color;
                color: #ffffff;
                padding: 0px 4px;
                border-radius: 2px;
                margin-right: 5px;
            }

            .is_own {
                color: #ffffff;
                background: #ff850f;
                border: 1px solid;
                margin-right: 5px;
                display: flex;
                align-items: center;
                padding: 3px 4px;
                border-radius: 2px;
            }

            .promotion-type {
                color: $base-color;
                border: 1px solid $base-color;
                display: flex;
                align-items: center;
                padding: 1px 3px;
            }
        }

        .item-bottom {
            display: flex;
            margin-top: 5px;

            .collection {
                flex: 1;
                border: 1px solid #e9e9e9;
                border-right: none;
                border-left: none;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }

            .cart {
                flex: 2;
                border: 1px solid #e9e9e9;
                border-right: none;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }
        }
    }
}
.price-icon-wrap {
    display: inline-block;
    max-width: 35px;
    margin-left: 3px;
    line-height: 1;
    margin-top: 4px;
    img {
        max-width: 100%;
    }
}

.empty {
    margin-top: 45px;
}

.pager {
    text-align: center;
    margin-top: 30px;
}
</style>
